// FullScreenModal.js

import { motion } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';

const FullScreenModal = ({ title, description, onClose, children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="fixed inset-0 bg-white z-50 flex flex-col text-black"
    >
      {/* Header with Back Button */}
      <header className="flex items-center p-4 bg-gray-100 shadow-md">
        <button onClick={onClose} className="text-blue-500 flex items-center space-x-2">
          <FaArrowLeft className="text-xl" />
          <span className="text-lg font-medium">Back</span>
        </button>
      </header>

      {/* Content */}
      <div className="flex flex-col items-center p-6 overflow-auto">
        <h1 className="text-3xl font-bold mb-4 text-center">{title}</h1>
        <p className="text-lg text-center mb-6">{description}</p>
        
        {children}
      </div>
    </motion.div>
  );
};

export default FullScreenModal;