import { motion } from 'framer-motion';

const Projects = ({ project, onSelectProject }) => {
  const ProjectIcon = project.icon;
  return (
    <div className="flex flex-col items-center">
      <motion.div
        onClick={() => onSelectProject(project)}
        whileHover={{ scale: 1.1 }}
        className="bg-white bg-opacity-30 p-6 rounded-2xl shadow-lg cursor-pointer text-center flex flex-col items-center"
      >
        <ProjectIcon className="text-6xl" style={{ color: project.color }} /> {/* Increased to text-6xl */}
      </motion.div>
      <h3 className="text-sm font-semibold mt-2 text-white">{project.name}</h3>
    </div>
  );
};

export default Projects;