// data/skillsData.js
import { FaReact, FaWordpress, FaJsSquare, FaAndroid, FaDatabase } from 'react-icons/fa';
import { SiNextdotjs, SiKotlin, SiFirebase, SiDocker, SiGooglecloud, SiMongodb, SiPostgresql, SiPytorch, SiTensorflow } from 'react-icons/si';

const skillsData = {
  Frontend: [
    { name: 'React', icon: FaReact },
    { name: 'Next.js', icon: SiNextdotjs },
    { name: 'Wordpress', icon: FaWordpress },
    { name: 'React Native', icon: FaAndroid },
  ],
  Backend: [
    { name: 'Kotlin', icon: SiKotlin },
    { name: 'Firebase', icon: SiFirebase },
    { name: 'Google Cloud', icon: SiGooglecloud },
    { name: 'Docker', icon: SiDocker },
    { name: 'Postgres', icon: SiPostgresql },
    { name: 'MongoDB', icon: SiMongodb },
  ],
  AI_ML: [
    { name: 'Pytorch', icon: SiPytorch },
    { name: 'Tensorflow', icon: SiTensorflow },
    { name: 'LLM integration', icon: FaJsSquare },
  ],
};

export default skillsData;