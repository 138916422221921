// Skills.js

import { motion } from 'framer-motion';
import { Settings } from 'lucide-react';

const Skills = ({ onOpen }) => {
  return (
    <div className="flex flex-col items-center">
      <motion.div
        onClick={onOpen}
        whileHover={{ scale: 1.1 }}
        className="bg-white bg-opacity-30 p-6 rounded-2xl shadow-lg cursor-pointer text-center flex flex-col items-center"
      >
        <Settings className="text-6xl text-green-400" /> {/* Icon for opening modal */}
      </motion.div>
      <h3 className="text-sm font-semibold mt-2 text-white">Skills</h3>
    </div>
  );
};

export default Skills;